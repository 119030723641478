.stage-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.stage {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 210px;
}

.heading {
  border-radius: 10px;
  margin-bottom: 30px;
}

.sub-heading {
  font-size: 18px !important;
  line-height: 28px !important;
}
.stage-price {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
}
